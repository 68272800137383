<template>
  <div class="success">
    <div class="public-screen user-ugoki-screen middle-box animated fadeIn">
      <b-row class="justify-content-center m-t-xl">
        <img class="app-logo" src="@/assets/images/ugoki_logo.png" alt="Logo"/>
      </b-row>
      <h2 class="m-t-xl m-b-md">{{ $t('app.ugoki.payment.authorization.success.header') }}</h2>

      <div class="m-t-lg">
        <p>{{ $t('app.ugoki.payment.authorization.success.text') }}</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
.success {
  text-align: center;
}
</style>